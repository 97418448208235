import React from "react";
import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getAllStores } from "services/AdminApi/stores";

type TaxFilterFields = {
  store_id: number;
  tax_name: string;
  tax_value: string;
  start_date: string;
  end_date: string;
};

type TaxFiltersProps = {
  showFilters: boolean;
  setFilters: Function;
};

export default function TaxFilters({
  showFilters,
  setFilters,
}: TaxFiltersProps) {
  const { data: stores } = useQuery(["stores"], getAllStores, {
    onError: (error) => {
      console.log(error);
    },
  });

  const flexStyle: FlexProps = {
    gap: "20px",
    marginBottom: "20px",
    flexWrap: "wrap",
  };

  const boxStyle: BoxProps = {
    minWidth: "200px",
  };

  const { register, handleSubmit, reset } = useForm<TaxFilterFields>();

  const handleFilter = (data: TaxFilterFields) => {
    setFilters(data);
  };

  const handleReset = () => {
    reset();
    setFilters({});
  };

  if (showFilters) {
    return (
      <Box padding="25px">
        <form onSubmit={handleSubmit(handleFilter)}>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="store_id">Loja</FormLabel>
              <Select id="store_id" {...register("store_id")}>
                <option value="">Selecione uma loja</option>
                {stores?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </Box>
            <Box {...boxStyle}>
              <FormLabel htmlFor="tax_name">Nome da Taxa</FormLabel>
              <Input
                id="tax_name"
                placeholder="Digite o nome da taxa"
                {...register("tax_name")}
              />
            </Box>
          </Flex>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="tax_value">Valor da Taxa</FormLabel>
              <Input
                id="tax_value"
                placeholder="Digite o valor da taxa"
                {...register("tax_value")}
              />
            </Box>
          </Flex>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="start_date">Data de Início</FormLabel>
              <Input type="date" id="start_date" {...register("start_date")} />
            </Box>
            <Box {...boxStyle}>
              <FormLabel htmlFor="end_date">Data de Fim</FormLabel>
              <Input type="date" id="end_date" {...register("end_date")} />
            </Box>
          </Flex>
          <Button type="submit" colorScheme="purple" margin="5px">
            Filtrar
          </Button>
          <Button onClick={handleReset} margin="5px">
            Limpar
          </Button>
        </form>
      </Box>
    );
  }

  return <></>;
}
