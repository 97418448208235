import * as React from "react";
import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { MdMoreHoriz } from "react-icons/md";

interface ListOptionsMenuProps {
  data: any;
  onEdit?: (data: any) => void;
}

export function ListOptionsMenu({ data, onEdit }: ListOptionsMenuProps) {
  return (
    <Menu>
      <MenuButton>
        <Icon as={MdMoreHoriz} color="gray.400" w="20px" h="20px" />
      </MenuButton>
      <MenuList>
        {onEdit && <MenuItem onClick={() => onEdit(data)}>Editar</MenuItem>}
      </MenuList>
    </Menu>
  );
}
