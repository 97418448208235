import { UserData } from "models/User";
import { adminApi } from "./api";

export const getAllUsers = async (
  params?: GetAllUsers.Params
): Promise<GetAllUsers.Result> => {
  const adminUrl = `/api/admin/users`;
  try {
    const param: GetAllUsers.Params = {
      status: typeof params.status !== "boolean" ? undefined : params.status,
      situation_id: params.situation_id == "" ? undefined : params.situation_id,
      invoicing: params.invoicing == "" ? undefined : params.invoicing,
      name: params.name == "" ? undefined : params.name,
      page: params.page,
      limit: params.limit,
      createdAt: params.createdAt == "" ? undefined : params.createdAt,
    };

    console.log({ paramsGerUser: params });

    const users = await adminApi.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        params: param,
      }
    );

    return users.data as GetAllUsers.Result;
  } catch (err) {
    console.log(err);
  }
};

export const getUserDataNovos = async (
  params?: UserDataNovosParams
): Promise<FetchUserDataResponse> => {
  const formData = new FormData();
  formData.append("size", params?.size?.toString() || "10");
  formData.append("page", params?.page?.toString() || "1");
  formData.append("name", params?.name || "");
  formData.append("email", params?.email || "");
  formData.append("cellphone", params?.cellphone || "");
  formData.append("mail_date_code", params?.mail_date_code || "");
  formData.append("cnpj_razao", params?.cnpj_razao || "");
  formData.append("analise", params?.analise || "");
  formData.append("mail_active", params?.mail_active?.toString() || "");
  formData.append("activated", params?.activated?.toString() || "");

  try {
    const response = await adminApi.post("/api/admin/all", formData);
    return response.data as FetchUserDataResponse;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export interface UserDataNovosParams {
  size?: string | number | null;
  page?: string | number | null;
  name?: string;
  email?: string;
  cellphone?: string;
  mail_date_code?: string;
  cnpj_razao?: string;
  mail_active?: boolean;
  activated?: boolean;
  analise?: "Pedir" | "Corrigir" | "Aguardando" | "Aprovado" | "Rejeitado";
}

interface UserStats {
  novos_users: number;
  corrigir_novo: number;
  email_ativado: number;
  analise_novo: number;
  aprovado_novo: number;
  rejeitado_novo: number;
}

interface FetchUserDataResponse {
  current_page: number;
  data: UserData[];
  total: number;
  per_page: number;
  last_page: number;
  stats: UserStats;
}

export type User = {
  id: number;
  name: string;
  email: string;
  remember_token?: string | null;
  created_at: string;
  updated_at: string;
  role_id: number;
  cpf: string;
  birth_date: string;
  zipcode: string;
  address: string;
  city: string;
  state: string;
  complement: string;
  number: number;
  document_attachment: string;
  address_document_attachment: string;
  activated: boolean;
  cellphone?: string;
  invoicing?: string;
  total_stores: number;
  situation_id: number;
  situation_name:
    | "Ativo"
    | "Ativo e operante"
    | "Ativo e inoperante"
    | "Inativo";
  expired_situation_date: Date;
  complete_register: Boolean;
};

export namespace GetAllUsers {
  export type Result = {
    data: User[];
    total: number;
  };

  export type Params = {
    status?: boolean;
    situation_id?: string;
    name?: string;
    invoicing?: string;
    page?: number;
    limit?: number;
    createdAt?: string;
  };
}
