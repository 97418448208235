import { Tax } from "models/Tax";
import { adminApi } from "./api";

export interface TaxFilters {
  membership_plan_id?: number;
  method_id?: number;
  installment?: number;
  tax_type_id?: number;
  gateway_id?: number;
  transaction_actor_id?: number;
  description?: string;
  page?: number;
  per_page?: number;
  sort_by?: string;
  sort_direction?: "asc" | "desc";
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
  last_page: number;
}

export interface TaxResponse {
  data: Tax[];
  meta: PaginatedResponse<Tax>;
}

export interface CreateTaxInput {
  membership_plan_id: number;
  method: number;
  installment: number;
  tax_type: number;
  percentage: number;
  sum: number;
  gateway: string;
  transaction_actor: number;
  description: string;
}

export interface UpdateTaxInput extends CreateTaxInput {}

export async function getAllTaxes(
  filters: TaxFilters = {},
  page: number = 1,
  per_page: number = 15
): Promise<PaginatedResponse<Tax>> {
  const response = await adminApi.get(
    `${process.env.REACT_APP_API_URL}/api/admin/taxes`,
    {
      params: {
        ...filters,
        page,
        per_page,
      },
    }
  );
  return response.data.data;
}

export async function createTax(input: CreateTaxInput): Promise<Tax> {
  const response = await adminApi.post(
    `${process.env.REACT_APP_API_URL}/api/admin/taxes`,
    {
      membership_plan_id: input.membership_plan_id,
      method: input.method,
      installment: input.installment,
      tax_type: input.tax_type,
      percentage: input.percentage,
      sum: input.sum,
      gateway: input.gateway,
      transaction_actor: input.transaction_actor,
      description: input.description,
    }
  );
  return response.data.data;
}

export async function updateTax(
  taxId: number,
  input: UpdateTaxInput
): Promise<Tax> {
  const response = await adminApi.put(
    `${process.env.REACT_APP_API_URL}/api/admin/taxes/${taxId}`,
    {
      membership_plan_id: input.membership_plan_id,
      method: input.method,
      installment: input.installment,
      tax_type: input.tax_type,
      percentage: input.percentage,
      sum: input.sum,
      transaction_actor: input.transaction_actor,
      description: input.description,
    }
  );
  return response.data.data;
}
