import * as React from "react";
import {
  Box,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import Card from "components/card/Card";
import { formatDate, formatTime } from "helper/time";
import { formatCurrency } from "helper/currency";
import { ListOptionsMenu } from "components/menu/ListOptionsTaxes";
import ShowFilters from "components/filter/ShowFilters";
import PaginationButtons from "components/paginationButtons/PaginationButtons";
import TaxFilters from "./TaxFilters";
import { PaginatedResponse } from "services/AdminApi/taxes";
import { Tax } from "models/Tax";

const columnHelper = createColumnHelper<Tax>();

type CheckTableProps = {
  tableData: PaginatedResponse<Tax>;
  tableName: string;
  setFilters: (filters: any) => void;
  showDetails: Function;
  page: number;
  setPage: Function;
  onEdit: Function;
};

export default function CheckTableTaxes({
  tableData,
  tableName,
  setFilters,
  showDetails,
  page,
  setPage,
  onEdit,
}: CheckTableProps) {
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [sorting, setSorting] = React.useState<{
    sort_by: string | null;
    sort_direction: "asc" | "desc" | null;
  }>({ sort_by: null, sort_direction: null });

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const columns = [
    columnHelper.accessor("method", {
      id: "method",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Método
        </Text>
      ),
      cell: (info) => {
        const methodMap: { [key: string]: string } = {
          credit: "Crédito",
          debit: "Débito",
          pix: "PIX",
          invoice: "Boleto",
        };
        return (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()?.name
              ? methodMap[info.getValue()?.name] ?? info.getValue()?.name
              : "-"}
          </Text>
        );
      },
    }),
    columnHelper.accessor("tax_type_id", {
      id: "tax_type_id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Tipo
        </Text>
      ),
      cell: (info) => {
        const taxTypeMap: { [key: number]: string } = {
          1: "Antecipação",
          2: "Transação",
          3: "Gateway",
          4: "Saque",
          5: "Antifraude",
          6: "Compra mínima",
          7: "Extra",
        };
        return (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {taxTypeMap[info.getValue()] || info.getValue()}
          </Text>
        );
      },
    }),
    columnHelper.accessor("payment_gateway", {
      id: "payment_gateway",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Gateway
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()?.name.toUpperCase() ?? "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("transaction_actor", {
      id: "transaction_actor",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Ator
        </Text>
      ),
      cell: (info) => {
        const actorMap: { [key: string]: string } = {
          seller: "Vendedor",
          customer: "Cliente",
          dubpay: "Dubpay",
        };
        return (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()?.name
              ? actorMap[info.getValue()?.name] ?? info.getValue()?.name
              : "-"}
          </Text>
        );
      },
    }),

    columnHelper.accessor("sum", {
      id: "sum",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Valor
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {formatCurrency(Number(info.getValue()))}
        </Text>
      ),
    }),

    columnHelper.accessor("percentage", {
      id: "percentage",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Percentual
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {`${Number(info.getValue()).toFixed(2).replace(".", ",")}%`}
        </Text>
      ),
    }),

    columnHelper.accessor("description", {
      id: "description",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Descrição
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() ?? "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("installment", {
      id: "installment",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Parcelas
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}x
        </Text>
      ),
    }),
    columnHelper.accessor("updated_at", {
      id: "updated_at",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Data da Última Atualização
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {formatDate(info.getValue())} - {formatTime(info.getValue())}
        </Text>
      ),
    }),
    {
      id: "options",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Opções
        </Text>
      ),
      cell: (info: CellContext<Tax, string>) => (
        <ListOptionsMenu
          key={info.row.original.id}
          data={info.row.original}
          onEdit={() => onEdit(info.row.original)}
        />
      ),
    },
  ];

  const data = tableData?.data;
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleSort = (columnId: string) => {
    if (columnId === "options") return;

    setSorting((prev) => {
      const newDirection: "asc" | "desc" | null =
        prev.sort_by === columnId
          ? prev.sort_direction === "asc"
            ? "desc"
            : prev.sort_direction === "desc"
            ? null
            : "asc"
          : "asc";

      const newSorting = {
        sort_by: newDirection ? columnId : null,
        sort_direction: newDirection,
      };

      setPage(1);
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        sort_by: newSorting.sort_by,
        sort_direction: newSorting.sort_direction,
      }));

      return newSorting;
    });
  };

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {tableName}
        </Text>
        <ShowFilters
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
      </Flex>
      <TaxFilters showFilters={showFilters} setFilters={setFilters} />
      {data && (
        <Box>
          <Table
            variant="simple"
            color="gray.500"
            mb="24px"
            justifyContent="center"
          >
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={() => handleSort(header.column.id)}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {sorting.sort_by === header.column.id && (
                            <Text ml={2}>
                              {sorting.sort_direction === "asc" ? "↑" : "↓"}
                            </Text>
                          )}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <PaginationButtons
            onClick={setPage}
            currentPage={page}
            lastPage={tableData?.last_page}
          />
        </Box>
      )}
    </Card>
  );
}
