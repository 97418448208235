import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { Tax } from "models/Tax";
import { createTax, updateTax, CreateTaxInput } from "services/AdminApi/taxes";

interface TaxFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  tax?: Tax;
  onSuccess: () => void;
}

export function TaxFormModal({
  isOpen,
  onClose,
  tax,
  onSuccess,
}: TaxFormModalProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<CreateTaxInput>({
    membership_plan_id: 0,
    method: 0,
    installment: 1,
    tax_type: 0,
    percentage: 0,
    sum: 0,
    gateway: "",
    transaction_actor: 0,
    description: "",
  });

  useEffect(() => {
    if (tax) {
      const methodMap: { [key: string]: string } = {
        Crédito: "credit",
        Débito: "debit",
        PIX: "pix",
        Boleto: "invoice",
      };

      const actorMap: { [key: string]: string } = {
        Vendedor: "seller",
        Cliente: "customer",
        Dubpay: "dubpay",
      };

      setFormData({
        membership_plan_id: tax.membership_plan_id,
        method: tax.method?.id || 0,
        installment: tax.installment,
        tax_type: Number(tax.tax_type_id),
        percentage: Number(tax.percentage),
        sum: Number(tax.sum),
        gateway: tax.payment_gateway?.name || "",
        transaction_actor: tax.transaction_actor?.id || 0,
        description: tax.description || "",
      });
    } else {
      setFormData({
        membership_plan_id: 0,
        method: 0,
        installment: 1,
        tax_type: 0,
        percentage: 0,
        sum: 0,
        gateway: "",
        transaction_actor: 0,
        description: "",
      });
    }
  }, [tax, isOpen]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (tax) {
        await updateTax(tax.id, formData);
      } else {
        await createTax(formData);
      }
      toast({
        title: `Taxa ${tax ? "atualizada" : "criada"} com sucesso!`,
        status: "success",
      });
      onSuccess();
      onClose();
    } catch (error: any) {
      toast({
        title: "Erro ao salvar taxa",
        description: error.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{tax ? "Editar Taxa" : "Nova Taxa"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} pb={6}>
            <FormControl>
              <FormLabel>Método de Pagamento</FormLabel>
              <Select
                value={formData.method}
                onChange={(e) =>
                  setFormData({ ...formData, method: Number(e.target.value) })
                }
              >
                <option value="1">Crédito</option>
                <option value="2">Débito</option>
                <option value="3">PIX</option>
                <option value="4">Boleto</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Tipo de Taxa</FormLabel>
              <Select
                value={formData.tax_type}
                onChange={(e) =>
                  setFormData({ ...formData, tax_type: Number(e.target.value) })
                }
              >
                <option value="1">Antecipação</option>
                <option value="2">Transação</option>
                <option value="3">Gateway</option>
                <option value="4">Saque</option>
                <option value="5">Antifraude</option>
                <option value="6">Compra mínima</option>
                <option value="7">Extra</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Ator da Transação</FormLabel>
              <Select
                value={formData.transaction_actor}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    transaction_actor: Number(e.target.value),
                  })
                }
              >
                <option value="1">Vendedor</option>
                <option value="2">Cliente</option>
                <option value="3">Dubpay</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Valor</FormLabel>
              <Input
                type="number"
                value={formData.sum}
                onChange={(e) =>
                  setFormData({ ...formData, sum: Number(e.target.value) })
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Percentual</FormLabel>
              <Input
                type="number"
                value={formData.percentage}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    percentage: Number(e.target.value),
                  })
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Parcelas</FormLabel>
              <Input
                type="number"
                value={formData.installment}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    installment: Number(e.target.value),
                  })
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Descrição</FormLabel>
              <Input
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </FormControl>

            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isLoading}
              width="100%"
            >
              {tax ? "Atualizar" : "Criar"}
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
