import React from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getAllTaxes } from "services/AdminApi/taxes";
import { Tax } from "models/Tax";
import LoadingPage from "components/loading/LoadingPage";
import CheckTable from "./components/CheckTableTaxes";
import { TaxFormModal } from "./components/TaxFormModal";

export default function TaxesMenu() {
  const [filters, setFilters] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [details, setDetails] = React.useState<Tax>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedTax, setSelectedTax] = React.useState<Tax | undefined>();

  const { data, isLoading, refetch } = useQuery(
    ["taxes", { page, filters }],
    () => getAllTaxes(filters, page, 15),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleCreateTax = () => {
    setSelectedTax(undefined);
    setIsModalOpen(true);
  };

  const handleEditTax = (tax: Tax) => {
    setSelectedTax(tax);
    setIsModalOpen(true);
  };

  return (
    <Box pt={{ base: "130px", md: "80px" }}>
      {details ? (
        <>
          <Button onClick={() => setDetails(null)}>Voltar</Button>
          {/* <TaxDetails
            tax={details}
            setOrder={(order: Tax) => {
              setDetails(order);
              refetch();
            }}
          /> */}
        </>
      ) : (
        <>
          <Flex justifyContent="flex-end" mb={4}>
            <Button colorScheme="blue" onClick={handleCreateTax}>
              Nova Taxa
            </Button>
          </Flex>

          <CheckTable
            tableData={data}
            tableName="Taxas"
            setFilters={setFilters}
            showDetails={setDetails}
            page={page}
            setPage={setPage}
            onEdit={handleEditTax}
          />
        </>
      )}

      <TaxFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        tax={selectedTax}
        onSuccess={refetch}
      />

      <LoadingPage isOpen={isLoading || data === undefined} />
    </Box>
  );
}
